<div (clickOutside)="isOpen = false" class="time-picker-select">
  <div (click)="isOpen = true"
       [class.handInput]="handInput"
       [class.has-error]="options.isEdit && !value"
       [ngStyle]="{'border-color': borderColor}"
       class="input-container">
    <div *ngIf="handInput" class="clock-time">
      <img alt="clock" src="assets/img/calendar-page/clock.svg">
      <span appAwesomeTooltip
            class="time-tooltip">Available time from {{ minTimeSlot | uppercase }}
        to {{ options.isMaxTime && maxTime ? (maxTimeSlot | uppercase) : '11:59 PM' }}</span>
    </div>
    <label>
      <input
        [(ngModel)]="value"
        [attr.class]="'input-value'"
        [disabled]="disabledInput"
        [placeholder]="placeholderValue"
        [readonly]="!handInput"
        appTimePicker
        value=""/>
    </label>
  </div>
  <ng-container *ngIf="!handInput">
    <div [hidden]="!isOpen" class="time-slots">
      <div [hidden]="!options.isMinTime">
        <div (touchmove)="onTouchmove($event)" class='scroll-time-slots'>
          <ng-container *ngFor="let item of timeSlots; let i = index">
            <div (click)="value = item.name; isOpen = false;"
                 *ngIf="i !== timeSlots.length-1"
                 [class.active]="value === item.name"
                 [class.disabled]="item.disable"
                 class="time-slots__item">
              {{ item.name }}
              <img *ngIf="value === item.name" alt="select" src="assets/img/checked-time.svg">
            </div>
          </ng-container>
        </div>
      </div>

      <div [hidden]="!options.isMaxTime">
        <div (touchmove)="onTouchmove($event)" class='scroll-time-slots'>
          <div (click)="value = item.name; isOpen = false;"
               *ngFor="let item of timeSlots; let i = index"
               [class.active]="value === item.name"
               [class.disabled]="item.disable"
               class="time-slots__item">
            {{ item.name }}
            <img *ngIf="value === item.name" alt="select" src="assets/img/checked-time.svg">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

